import { sendUiEvent } from '@/core';
import { KnapsackNavItem } from '@knapsack/types';
import { TreeItem } from 'react-sortable-tree';

export function setExpandedNavIds({
  items,
  activeNavItemParentIds,
  activeNavItem,
}: {
  items: TreeItem[];
  activeNavItemParentIds: string[];
  activeNavItem: KnapsackNavItem;
}) {
  return items.map((item) => {
    const shouldExpand =
      activeNavItemParentIds.includes(item.id) ||
      (activeNavItem?.id === item.id && item.children?.length > 0);

    // Set expanded property directly on the item
    if (shouldExpand) {
      item.expanded = true;
      sendUiEvent({
        type: 'nav.setExpandedNavIds',
        add: item.id,
      });
    }

    if (item.children?.length > 0) {
      item.children = setExpandedNavIds({
        items: item.children as TreeItem[],
        activeNavItemParentIds,
        activeNavItem,
      });
    }
    return item;
  });
}
