import { sendAppEvent, sendUiEvent } from '@/core';
import { ModalProps } from '@/core/layout/modal';
import { SiteInstanceStatuses_Enum } from '@knapsack/hasura-gql-client';
import {
  ButtonGroup,
  ButtonPrimary,
  ButtonSecondary,
  componentSpacing,
} from '@knapsack/toby';

const DismissButton = () => {
  return (
    <ButtonSecondary
      fullWidth
      label="Dismiss"
      onTrigger={() => {
        sendUiEvent('modal.triggerClose');
      }}
    />
  );
};

const GoToLatestButton = () => {
  return (
    <ButtonPrimary
      fullWidth
      label="Go to Latest"
      onTrigger={() => {
        sendUiEvent('modal.triggerClose');
        sendAppEvent({
          type: 'site.switchInstance',
          instance: { type: 'latest' },
        });
      }}
    />
  );
};

export function getBranchStatusContent({
  instanceStatus,
}: {
  instanceStatus: SiteInstanceStatuses_Enum;
}): ModalProps & {
  title: string;
} {
  if (!instanceStatus) return null;
  switch (instanceStatus) {
    case 'CLEAN_DRAFT':
    case 'DRAFT':
      return {
        testId: 'branchStatusIntercept--DRAFT',
        title: 'Branch Status Updated to Draft',
        subtitle: `This branch was in review but has been moved back to a draft. You can now make changes as needed before submitting it for review again.`,
        body: <DismissButton />,
      };
    case 'DELETED':
      return {
        testId: 'branchStatusIntercept--DELETED',
        title: 'Oops! This Branch Has Been Deleted',
        subtitle: `It looks like the branch you're trying to access has been deleted, so it’s no longer available for editing. Need to make more changes? No problem! Just create a new branch from the latest version.`,
        body: <GoToLatestButton />,
        bodyClassName: componentSpacing({ marginBlock: 'medium' }),
        preventClose: true,
        preventEscKeyClose: true,
      };
    case 'PROPOSING':
    case 'PROPOSED':
      return {
        testId: 'branchStatusIntercept--PROPOSED',
        title: 'This Branch Has Been Marked as Ready to Review',
        subtitle: `It looks like the branch you're trying to access has been marked as ready to review, so it’s no longer available for editing. Need to make more changes? No problem! Just dismiss this message and click Edit to make more changes.`,
        body: (
          <ButtonGroup>
            <DismissButton />
            <GoToLatestButton />
          </ButtonGroup>
        ),
        bodyClassName: componentSpacing({ marginBlock: 'medium' }),
      };
    case 'PUBLISHING':
    case 'PUBLISHED':
      return {
        testId: 'branchStatusIntercept--PUBLISHED',
        title: 'This Branch Has Already Been Published',
        subtitle: `It looks like the branch you're trying to access has already been published, so it’s no longer available for editing. Need to make more changes? No problem! Just create a new branch from the latest version.`,
        body: <GoToLatestButton />,
        bodyClassName: componentSpacing({ marginBlock: 'medium' }),
        preventClose: true,
        preventEscKeyClose: true,
      };
    default: {
      const _exhaustiveCheck: never = instanceStatus;
      throw new Error(`Unhandled instance status: ${_exhaustiveCheck}`);
    }
  }
}

export function showBranchStatusIntercept({
  instanceStatus,
}: {
  instanceStatus: SiteInstanceStatuses_Enum;
}) {
  sendUiEvent([
    {
      type: 'modal.setContent',
      modal: {
        ...getBranchStatusContent({ instanceStatus }),
        size: 'wide',
      },
    },
    { type: 'modal.triggerOpen' },
  ]);
}
